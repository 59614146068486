import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const ColumnListing = ({ module, microSitename }) => {
  let column = 6;
  if (module.variation === 'One Column') {
    column = 12;
  } else if (module.variation === 'Two Columns') {
    column = 6;
  } else if (module.variation === 'Three Columns') {
    column = 4;
  } else if (module.variation === 'Four Columns') {
    column = 3;
  }
  return (
    <>
      <section
        className={`img-grid portrait lg:py-60 py-30 ${module.extraClass}`}
      >
        <div className="container-fluid">
          {module.heading && (
            <div className={`title-black  text-left lg:pb-30 pb-30  fade-ani`}>
              <h3>{module.heading}</h3>
            </div>
          )}
          <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
            {module.item &&
              module.item.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`lg:w-${column}/12 w-full lg:px-20 fade-img`}
                  >
                    <Link to={item.cta?.url}>
                      <img
                        src={item.image.imgixImage.fluid.srcWebp}
                        alt={item.image.altText}
                        className="w-full object-cover"
                        width="890"
                        height="647"
                        loading="lazy"
                      />
                    </Link>
                    <div className="img-content pt-15 max-w-540">
                      {item.preheading && (
                        <Link to={item.cta.url}>
                          <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                            {item.preheading}
                          </span>
                        </Link>
                      )}
                      <h4 className="text-black-200 lg:text-[28px]">
                        {' '}
                        {item.heading}
                      </h4>
                      <span className="uppercase text-dark text-11 font-400 font-preheaders">
                        {item.subheading}
                      </span>
                      <div className="content text-sm my-15">
                        {item.description && parse(item.description)}
                      </div>
                      {item.cta && (
                        <Link to={item.cta.url} className="link-btn">
                          {item.cta.title}
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ColumnListing;

export const ColumnListingFragment = graphql`
  fragment ColumnListingFragment on WpPage_Flexiblecontent_FlexibleContent_ColumnListing {
    # content
    fieldGroupName
    heading
    variation
    extraClass
    subheading
    item {
      subheading
      preheading
      image {
        imgixImage {
          fluid(
            maxWidth: 890
            maxHeight: 647
            imgixParams: { w: 890, h: 647, fit: "crop" }
          ) {
            srcWebp
          }
        }
        altText
        uri
        title
      }
      cta {
        url
        title
        target
      }
      description
      fieldGroupName
      heading
    }
  }
`;
export const PrivateResidenceColumnListingFragment = graphql`
  fragment PrivateResidenceColumnListingFragment on WpPrivateResidence_Flexiblecontent_FlexibleContent_ColumnListing {
    # content
    fieldGroupName
    heading
    variation
    extraClass
    subheading
    item {
      subheading
      preheading
      image {
        imgixImage {
          fluid(
            maxWidth: 890
            maxHeight: 647
            imgixParams: { w: 890, h: 647, fit: "crop" }
          ) {
            srcWebp
          }
        }
        altText
        uri
        title
      }
      cta {
        url
        title
        target
      }
      description
      fieldGroupName
      heading
    }
  }
`;
export const ColumnListingPropertyFragment = graphql`
  fragment ColumnListingPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_ColumnListing {
    # content
    fieldGroupName
    heading
    variation
    extraClass
    subheading
    item {
      subheading
      preheading
      image {
        imgixImage {
          fluid(
            maxWidth: 890
            maxHeight: 647
            imgixParams: { w: 890, h: 647, fit: "crop" }
          ) {
            srcWebp
          }
        }
        altText
        uri
        title
      }
      cta {
        url
        title
        target
      }
      description
      fieldGroupName
      heading
    }
  }
`;

export const ColumnListingPressFragment = graphql`
  fragment ColumnListingPressFragment on WpPress_Flexiblecontent_FlexibleContent_ColumnListing {
    # content
    fieldGroupName
    heading
    variation
    extraClass
    subheading
    item {
      subheading
      preheading
      image {
        imgixImage {
          fluid(
            maxWidth: 890
            maxHeight: 647
            imgixParams: { w: 890, h: 647, fit: "crop" }
          ) {
            srcWebp
          }
        }
        altText
        uri
        title
      }
      cta {
        url
        title
        target
      }
      description
      fieldGroupName
      heading
    }
  }
`;
