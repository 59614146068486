import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const TestimonialListing = ({ module, microSitename }) => {
  const properties = useStaticQuery(graphql/* GraphQL */ `
    query Wptestimonials {
      allWpTestimonial {
        edges {
          node {
            TestimonialContent {
              fieldGroupName
              role
            }
            uri
            title
            content
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `);
  const data = microSitename
    ? properties.allWpTestimonial.edges.filter(function (value) {
        return (
          value.node.categories.nodes[0]?.name === microSitename &&
          value.node.categories.nodes.length > 0
        );
      })
    : properties.allWpTestimonial.edges.filter(function (value) {
        return value.node.categories.nodes.length === 0;
      });
  return (
    <>
      <section
        className={`testimonial-slider py-60  bg-white ${module.extraClass}`}
      >
        <div className="container m-auto fade-ani">
          <div className="text-center">
            <img
              src="https://trp.imgix.net/https%3A%2F%2Fwp.theroyalportfolio.com%2Fapp%2Fuploads%2F2022%2F08%2FCrown-Icon.png?ixlib=gatsbySourceUrl-2.1.1&auto=format%2Ccompress&dpr=2&h=24&q=100&w=45&s=3b8cc58e097ea6c9040edc5886d23596"
              className="m-auto w-45"
              width="45"
              height="24"
              loading="lazy"
              alt="testimonial"
            />
          </div>
          <div className="testimonial-slider-content">
            <Swiper
              loop={true}
              slidesPerView={1}
              allowTouchMove={true}
              grabCursor={true}
              autoHeight={true}
              navigation={true}
              autoplay={{ delay: 8000 }}
              pagination={{
                type: 'progressbar',
              }}
              modules={[Pagination, Navigation, Autoplay]}
            >
              {data &&
                data.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="testimonial-content h-[325px] lgscreen:h-[275px] mdscreen:h-[400px] px-20 text-center max-w-950 m-auto mt-50">
                        <h5 className="!font-body !not-italic">
                          {item.node.content && parse(item.node.content)}
                        </h5>
                        <div className="testimonial-btm lg:mt-50 mt-25">
                          <span className="text-black-200 font-400 mdscreen:!text-[24px]">
                            {item.node.title}
                          </span>
                          {item?.node.TestimonialContent?.role && (
                            <p className="text-16 mb-10 text-black-200 font-500">
                              {item?.node.TestimonialContent?.role}
                            </p>
                          )}
                          {item.node.categories.nodes.length > 0 && (
                            <p className="text-14 text-gray-800 font-400">
                              {item.node.categories.nodes[0].name}
                            </p>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialListing;

export const TestimonialListingFragment = graphql`
  fragment TestimonialListingFragment on WpPage_Flexiblecontent_FlexibleContent_TestimonialListing {
    # content
    fieldGroupName
    extraClass
    testimonialListing {
      name
      fieldGroupName
      details
      designation
    }
  }
`;
export const PrivateResidenceTestimonialListingFragment = graphql`
  fragment PrivateResidenceTestimonialListingFragment on WpPrivateResidence_Flexiblecontent_FlexibleContent_TestimonialListing {
    # content
    fieldGroupName
    extraClass
    testimonialListing {
      name
      fieldGroupName
      details
      designation
    }
  }
`;
export const TestimonialListingPropertyFragment = graphql`
  fragment TestimonialListingPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_TestimonialListing {
    # content
    fieldGroupName
    extraClass
    testimonialListing {
      name
      fieldGroupName
      details
      designation
    }
  }
`;
