import { graphql } from 'gatsby';
import React from 'react';
import ColumnListing from '../components/ColumnListing';
import ImageContent from '../components/ImageContent';
import PropertyHighlight from '../components/PropertyHighlight';
import RelatedCards from '../components/RelatedCards';
import TestimonialListing from '../components/TestimonialListing';
import PropertyHighlightRelationship from '../components/PropertyHighlightRelationship'

const PrivateResidenceModules = (props) => {
  const modules = props.modules.flexibleContent;
  const { location } = props;
  const components = {
    ImageContent,
    ColumnListing,
    TestimonialListing,
    RelatedCards,
    PropertyHighlight,
    PropertyHighlightRelationship,
  };
  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpPrivateResidence_Flexiblecontent_FlexibleContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                i,
              })
            );
          })
        : null}
    </>
  );
};

export default PrivateResidenceModules;

export const PrivateResidenceModulesFragment = graphql`
  fragment PrivateResidenceModulesFragment on WpPrivateResidence {
    flexibleContent {
      flexibleContent {
        __typename
        ...PrivateResidenceImageContentFragment
        ...PrivateResidenceColumnListingFragment
        ...PrivateResidenceTestimonialListingFragment
        ...PrivateResidenceRelatedCardsFragment
        ...PrivateResidencePropertyHightlightFragment
        ...PrivateResidencePropertyHighlightRelationshipFragment
      }
    }
  }
`;
